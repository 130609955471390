import React, { Component } from 'react';


class CriteoHome extends Component {
    constructor(props){
        super(props)
        this.state = {
        }
    }
    componentDidMount = () => {
        var ss=localStorage.getItem("ss");ss=JSON.parse(ss);if(ss==null){var cemail="";}else{var cemail=ss['email'];};
        
        const script = document.createElement("script");
        script.src = "//static.criteo.net/js/ld/ld.js";
        script.async = true;
        document.body.appendChild(script);

        let tela = "d";
        if(window.innerWidth > 575 && window.innerWidth < 1024){
             tela="t"
        }
        if(window.innerWidth < 575){
             tela="m"
        }
        window.criteo_q = window.criteo_q || [];
        window.criteo_q.push(
            { event: "setAccount", account: 32004 },
            { event: "setEmail", email: `${cemail}` },
            { event: "setSiteType", type: `"${tela}"` },
            { event: "viewHome"}
        );
    }

    render(){
        return (
            <></>
        )
    }
}

export default CriteoHome;
