import React, { Component } from "react";

class NavBarLateralHome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <nav>
        <ul
          className="nav nav-pills flex-column"
          role="tablist"
          aria-orientation="vertical">
          <li className="nav-item">
            <a
              className="nav-link menu-vertical-lojamundi linkMenuPrincipal mb-1"
              href="/promocao">
              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
              Promoções
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link menu-vertical-lojamundi linkMenuPrincipal mb-1"
              href="/telefone-ip">
              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
              Telefones IP
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link menu-vertical-lojamundi linkMenuPrincipal mb-1"
              href="/fone-de-ouvido">
              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
              Headsets
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link menu-vertical-lojamundi linkMenuPrincipal mb-1"
              href="/rede">
              <i className="fa fa-chevron-right" aria-hidden="true"></i> Redes
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link menu-vertical-lojamundi linkMenuPrincipal mb-1"
              href="/gateway-celular">
              <i className="fa fa-chevron-right" aria-hidden="true"></i> Gateway
              Celular
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link menu-vertical-lojamundi linkMenuPrincipal mb-1"
              href="/gateway-voip">
              <i className="fa fa-chevron-right" aria-hidden="true"></i> Gateway
              Voip
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link menu-vertical-lojamundi linkMenuPrincipal mb-1"
              href="/computadores-e-informatica">
              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
              Informática
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link menu-vertical-lojamundi linkMenuPrincipal mb-1"
              href="/camera">
              <i className="fa fa-chevron-right" aria-hidden="true"></i> Câmeras
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default NavBarLateralHome;
