import React, { Component } from 'react';
import Link from 'next/link'
import CardProduto from '../comuns/CardProduto.js'

class DestaquesHome extends Component {
    constructor(props){
        super(props)
        this.state = {
            produtos:[]
        }
    }
    render(){
        return (
            <section>
                    <div className="container">
                        <div className="text-center pt-5 mb-2 titulo"><img src="/imagens/imagemTitulo.png" loading="lazy"/><br/>PRODUTOS QUE VOCÊ NÃO PODE PERDER</div>
                        <div className="text-center mb-5"><img src="/imagens/linhaBottomTitulo.png" loading="lazy"/></div>
                    </div>
                    <div className="container">
                        <div className="row mb-3">
                            <CardProduto productCards={this.props.destaquesHome} cards={3}/>
                        </div>
                    </div>
            </section>
            )
    }
}


export default DestaquesHome;
