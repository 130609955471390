import React, {Component} from 'react';
import {ContCarrinho} from '../ContextLojamundi.js';

class CardProduto extends Component {
   constructor(props) {
      super(props)
      this.state = {}
   }
   componentDidMount = () => {
      if (!this.props.productCards) {
         window
            .history
            .back()
      }
   }

   setBestPosition = (id) => {
      fetch(`/api2/bestPosition?id=${id}`)
   }

   static contextType = ContCarrinho
   addItem = (url_publica, name, imageName, list_price, default_code, id, gtin_site, estoque_sp) => {
      this
         .context
         .addItem({
            nome: name,
            sku: default_code,
            preco: list_price,
            qty: 1,
            total: list_price,
            id: id,
            imagem: `/imagens/produtos/${imageName.endsWith('jpg')
                    ? imageName
                  : imageName + '.jpg'}`,
            gtin: gtin_site,
            origemEstoque: estoque_sp > 0
            ? 'SP'
            : 'BA',
            estoqueSP: estoque_sp
         })

      gtag('event', 'add_to_cart', {
           currency: 'BRL',
           items: [{
                  item_id: default_code,
                  item_name: name,
                  price: list_price - (list_price * 0.12),
                  currency: 'BRL',
                  quantity: 1
                }],
           value: list_price
      });
   }

   render() {
      if (this.props.productCards) {
         let cards = this.props.productCards.map((item, index) => {
            let {
               url_publica,
               name,
               imageName,
               list_price,
               preco_padrao,
               preco_avista,
               estoque_site,
               default_code,
               id,
               gtin_site,
               estoque_sp
            } = item
            if (imageName && name && url_publica) {
               return (
                  <div id="marcacao" 
                     key={index}
                     className={this.props.cards
                        ? `col-sm-${this.props.cards} pt-3`
                        : "col-sm-4 pt-3"}>
                     <div className="card inicialCard containerProduto h-100 w-100">
                        <a href={`/${url_publica}`}>
                           <picture>
                              <source
                                 type="image/webp"
                                 srcSet={`/imagens/produtos/${imageName.replace(/\.[a-z]{3}$/, '') + '.webp'}`}/>
                              <img
                                 src={`/imagens/produtos/${imageName.endsWith('jpg')
                                    ? imageName
                                    : imageName + '.jpg'}`}
                                 className="card-img-top py-3 px-3"
                                 alt={imageName}
                                 loading="lazy"
                              />
                           </picture>
                        </a>
                        <div className="card-body">
                           <h3 className="card-title text-center">{name.length > 36
                              ? `${name.substring(0, 36)}...`
                              : name}</h3>
                           <div className="card-text text-center">
                              <span className="valorPromocional">R$
                                 <span className="oe_currency_value">{list_price.toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                 })}</span>
                              </span>&nbsp;
                              <span className="valorAntigo">R$
                                 <span className="oe_currency_value">{preco_padrao.toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                 })}</span>
                              </span>
                              <br/>
                              <span style={{ "fontSize": "12px" }}>10x
                                 <span> R$
                                    <span className="oe_currency_value">{(list_price / 10).toLocaleString('pt-BR', {
                                       minimumFractionDigits: 2,
                                       maximumFractionDigits: 2
                                    })}</span>
                                 </span> ou
                                 <span className="valorBoleto19"> R$
                                    <span className="oe_currency_value">{preco_avista.toLocaleString('pt-BR', {
                                       minimumFractionDigits: 2,
                                       maximumFractionDigits: 2
                                    })}</span>
                                 </span> no boleto ou transferência bancária</span>
                           </div>
                        </div>
                        <div style={{borderTop: "none"}}
                           className="card-footer inicialFooter bg-transparent bordaCardFooterProdutos">
                           {estoque_site > 0 ?
                              <div onClick={e => this.addItem(url_publica, name, imageName, list_price, default_code, id, gtin_site, estoque_sp)}
                                 className="btn btn-danger btn-sm text-center">
                                 <i className="fa fa-cart-plus" aria-hidden="true"></i>
                                 Adicionar
                              </div>
                              :
                                 <button onClick={e => this.context.ModalTeLigamos.current.click()} className="btn btn-danger btn-sm">
                                    <i className="fa fa-cart-plus">Consulte Entrega</i>
                                 </button>
                           }
                        </div>
                     </div>
                  </div>
               )
            } else {
               return <React.Fragment key={index}></React.Fragment>
            }
         })
         return <>{cards}</ >
      } else {
         return <>< />
      }
   }
}
export default CardProduto;
