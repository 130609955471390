import React, {Component} from 'react';
import TopBar from '../comuns/TopBar'
import Busca from '../comuns/Busca'
import Box0800PC from '../comuns/Box0800PC.js'
import NavBarSuperior from '../comuns/NavBarSuperior.js'
import Logomarca from './Logomarca.js'
import DestaquesHome from './DestaquesHome';
import AddRecent from './AddRecent';
import AdiciondoRecente from '../comuns/AdiciondoRecente';
import Footer from '../comuns/Footer';
import MinutoLojamundi from '../comuns/MinutoLojamundi';
import MenuSuperiorCel from '../comuns/MenuSuperiorCel';
import LojamundiConfiavel from '../comuns/LojamundiConfiavel';
import NewsLetter from '../comuns/NewsLetter';
import BannerFooter from '../comuns/BannerFooter';
import Contato from '../comuns/Contato';
import BotaoTodosProdutos from '../comuns/BotaoTodosProdutos';
import CardTriplo from './CardTriplo'
import NavBarLateralHome from './NavBarLateralHome'
import Slider from './Slider.js'
import CriteoHome from './CriteoHome.js'
const {openChat} = require('../../funcoes/openChat')
import {ContCarrinho} from '../ContextLojamundi'

class BodyIndex extends Component {
   constructor(props) {
      super(props)
      this.state = {}
   }
   componentDidMount = () => {
      openChat('')
   }

   static contextType = ContCarrinho

   render() {
      //console.log(this.props)
      return (
         <> {
            this.props.isMobile && ( <> <div
               className="d-block d-lg-none pt-2 pb-2 fixed-top"
               style={{
                  boxSizing: 'border-box',
                  backgroundColor: '#292521'
               }}>
               <Busca/>
            </div> < div className = "d-block d-lg-none" style = {{marginBottom:"54px"}}/>
            </ >)
            } < TopBar /> <NavBarSuperior filtroCategoria={this.props.filtroCategoria}/>
            {
               !this.context.isMobile && (
                  <div className="bg-slider-lojamundi d-none d-lg-block">
                     <div className="container">
                        <div className="row">
                           <div className="col-lg-2">
                              <div className="mb-3 mt-4 text-center">
                                 <Logomarca/>
                              </div>
                              <NavBarLateralHome/>
                           </div>
                           <div className="col-lg-7">
                              <div
                                 style={{
                                    marginTop: "80px"
                                 }}>
                                 <Busca/>
                              </div>
                              <div
                                 style={{
                                    marginTop: "20px"
                                 }}>
                                 <Slider/>
                              </div>
                           </div>
                           <div className="col-lg-3 mt-4">
                              <Box0800PC/>
                           </div>
                        </div>
                     </div>
                  </div>
               )
            }
            { this.props.isMobile && (<MenuSuperiorCel/>) }
            { this.context.endPoint === 'desktop' && (<CardTriplo/>) }
            <DestaquesHome destaquesHome={this.props.destaquesHome} />
            {/*<AddRecent addRecent={this.props.addRecent}/>*/}
            <MinutoLojamundi/> 
            <LojamundiConfiavel/> 
            <NewsLetter/> 
            <Contato/>
            <Footer/>
            { this.props.siMobile && (<BotaoTodosProdutos filtroCategoria={this.props.filtroCategoria}/>) }
            <CriteoHome/>
         </>);
   }
}
export default BodyIndex
