import React, { Component } from 'react';

class Logomarca extends Component {
   constructor(props){
      super(props)
      this.state = {
      }
   }
   render(){
      return (
         <a href="/">
            <img src="/imagens/tecnologia-sem-limites-telefone-ip.png" loading="lazy" alt="Lojamundi Logomarca"/>
         </a>
      )
   }
}

export default Logomarca;
