import React, { Component } from 'react';
import { ContCarrinho } from '../ContextLojamundi'

class CardTriplo extends Component {
   constructor(props) {
      super(props)
      this.state = {
      }
   }
   static contextType = ContCarrinho
   render() {
      return (
         <section>
            <div className="d-none d-sm-none d-lg-block bg-categorias">
               <div className="container">
                  <div className="row">
                     {!this.context.outService && <>
                        <a href="/sala-comercial" target="_blank">
                           <div className="mt-4" style={{ backgroundImage: "url(imagens/backgroundAoVivo.png)", backgroundRepeat: "no-repeat", width: "904px", height: "234px" }}>
                              <div className="py-3 px-3"><img src="imagens/compre-ao-vivo.gif" loading="lazy" /></div>
                              <div className="pl-3 text-center">
                                 <picture className='mr-4'>
                                    <source type="image/webp" srcSet="/imagens/fabricio.webp" />
                                    <img src="/imagens/fabricio.png" loading="lazy" />
                                 </picture>
                                 &nbsp;
                                 {/*<picture>
                                    <source type="image/webp" srcSet="/imagens/camila.webp"/>
                                    <img src="/imagens/camila.png" loading="lazy" />
                                 </picture>
                     &nbsp;*/}
                                 <picture className='mr-4'>
                                    <source type="image/webp" srcSet="/imagens/daviGifHome.webp" />
                                    <img src="/imagens/daviGifHome.png" loading="lazy" />
                                 </picture>
                                 &nbsp;
                                 <picture className='mr-4'>
                                    <source type="image/webp" srcSet="/imagens/jakeline.webp" />
                                    <img src="/imagens/jakeline.png" loading="lazy" />
                                 </picture>
                                 &nbsp;
                                 <picture className='mr-4'>
                                    <source type="image/webp" srcSet="/imagens/moises.webp" />
                                    <img src="/imagens/moises.png" loading="lazy" />
                                 </picture>
                                 &nbsp;<picture className='mr-4'>
                                    <source type="image/webp" srcSet="/imagens/circuloGrandeArthur.webp" />
                                    <img src="/imagens/circuloGrandeArthur.gif" loading="lazy" />
                                 </picture>
                                 &nbsp;
                              </div>
                           </div>
                        </a>
                        <div className="pl-2 mt-4">
                           <a href="/sala-comercial" target="_blank"><img src="imagens/entrar-na-sala.png" /></a>
                        </div>
                     </>}
                     {this.context.outService && <>
                        <div className="col-md-4 mt-4 p-2">
                           <div className="categoriaTelefone">
                              <a className="linkCategoriaTelefoneIPOLD" href="/telefone-ip">
                                 <img src="/imagens/categoriaTransparente.png" loading="lazy" />
                              </a>
                           </div>
                        </div>
                        <div className="col-md-4 mt-4 p-2">
                           <div className="categoriaHeadset">
                              <a className="linkCategoriaHeadsetOLD" href="/fone-de-ouvido">
                                 <img src="/imagens/categoriaTransparente.png" loading="lazy" />
                              </a>
                           </div>
                        </div>
                        <div className="col-md-4 mt-4 p-2">
                           <div className="categoriaGateway">
                              <a className="linkCategoriaGatewayGSMOLD" href="/gateway-gsm">
                                 <img src="/imagens/categoriaTransparente.png" loading="lazy" />
                              </a>
                           </div>
                        </div>
                     </>}
                  </div>
               </div>
            </div>
         </section>
      )
   }
}

export default CardTriplo;
