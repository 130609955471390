const waitForEl = function (t, e, f)
{
   jQuery(t).length ? e(f) : setTimeout(function ()
      {
         waitForEl(t, e, f)
      }, 3e3)
};

const openChat = (f) => {
   setTimeout(() =>
      {
         waitForEl(".openerp.o_livechat_button.d-print-none", function (f){
            if(!JSON.parse(sessionStorage.getItem("chatO")) && $(window).width() > 768){
               sessionStorage.setItem("chatO", !0)
               if(!$('.o_thread_window.o_in_home_menu').length){
                  $(".openerp.o_livechat_button.d-print-none").click()
               }
               setTimeout(() => { 
                  if(f.length){
                     let sidebar = $('.o_thread_message_sidebar').html()
                     let msg = `<div class="o_thread_message o_mail_not_discussion" data-message-id="_welcome">
                     <div class="o_thread_message_sidebar">
                     ${sidebar}
                     </div>
                     <div class="o_thread_message_core">
                     ${f}
                     </div>
                     </div>`
                     $('.o_mail_thread .o_mail_thread_content').append(msg)
                  }
               },10000)
            }
         },f);
   },15000)
}

module.exports = {openChat}
