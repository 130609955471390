import React, { Component } from 'react';
import Link from 'next/link'
import CardProduto from '../comuns/CardProduto.js'

class AddRecent extends Component {
    constructor(props){
        super(props)
        this.state = {
            exten:'png'
        }

    }
    render(){
        return (
            <section>
                <div className="container">
                    <div className="text-center mb-2 mt-4 titulo"><img src="/imagens/imagemTitulo.png"/><br/>MAIS VENDIDOS</div>
                    <div className="text-center mb-5"><img src="/imagens/linhaBottomTitulo.png" loading="lazy"/></div>
                </div>
                <div className="container">
                    <div className="row mb-3">
                        <CardProduto productCards={this.props.addRecent} cards={3}/>
                    </div>
                </div>

            </section>
            )
    }
}


export default AddRecent;
