import React, { Component } from 'react';

class LojamundiConfiavel extends Component {
    constructor(props){
        super(props)
        this.state = {
            valor:''
        }
    }
    render(){
        return (
            <div style={{"overflow":"hidden"}} className="bg-LojamundiConfiavel">
                <div className="container">
                    <div className="text-center pt-5 mb-2 titulo"><img src="/imagens/imagemTitulo.png" loading="lazy"/><br/>POR QUE A LOJAMUNDI É CONFIÁVEL?</div>
                    <div className="text-center mb-5"><img src="/imagens/linhaBottomTitulo.png" loading="lazy"/></div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-3 text-center">
                        <a href="https://www.reclameaqui.com.br/empresa/loja-mundi-tecnologia-sem-limites/" target="_blank"><img src="/imagens/reclame-Aqui.png" loading="lazy" /></a>
                    </div>
                    <div className="col-lg-3 text-center">
                        <div className="signifyd mr-2"><a href="https://www.google.com/transparencyreport/safebrowsing/diagnostic/index.html#url=www.lojamundi.com.br" target="_blank"><img src="/imagens/google-site-seguro-lojamundi.png" loading="lazy" /></a>
                        </div>
                    </div>
                    <div className="col-lg-3 text-center">
                        <div className="signifyd mr-2">
                            <a href="https://www.google.com/transparencyreport/safebrowsing/diagnostic/index.html#url=www.lojamundi.com.br" target="_blank"><img src="/imagens/https-site-seguro-lojamundi.png" loading="lazy" /></a>
                        </div>
                    </div>
            
                    <div className="col-lg-3 text-center">
                        <div className="signifyd mr-2">
                            <a href="https://www.google.com/transparencyreport/safebrowsing/diagnostic/index.html#url=www.lojamundi.com.br" target="_blank"><img src="/imagens/lets-encrypt-lojamundi.png" loading="lazy" /></a>
                        </div>
                    </div>
            
            
            
            
                </div>
            </div>
            )
    }
}

export default LojamundiConfiavel;
