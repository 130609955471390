import React, { Component } from 'react';

class BotaoTodosProdutos extends Component {
    constructor(props){
        super(props)
        this.state = {
            categorias:[]
        }
    }
    componentWillMount = () => {
        let categorias = this.props.filtroCategoria.sort((a, b) => b.total - a.total)
        this.setState({categorias})
    }

    render(){
        return (
            <div id="bottomMenu" style={{display:'block'}} className="text-center d-md-none">

                <div style={{"position": "fixed", "top": "380px","right": "0%","zIndex":"9999"}}><button style={{"WebkitBorderTopLeftRadius": "20px","WebkitBorderBottomLeftRadius": "10px","MozBorderRadiusTopleft": "20px","MozBorderRadiusBottomleft": "10px","borderTopLeftRadius": "20px","borderBottomLeftRadius": "10px","paddingTop":"10px"}} type="button" className="btn btn-danger " data-toggle="modal" data-target="#exampleModalCenter">
                        <div style={{"writing-mode":"vertical-lr","fontSize":"12px"}}>Todos os <strong><span className="text-warning">Produtos</span></strong></div>
                </button></div>


                <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-danger">
                                <h3 className="modal-title text-white" id="exampleModalCenterTitle"><i className="fas fa-align-center"></i> Categorias dos Produtos</h3>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div style={{"backgroundImage":"url(/imagens/bg-submenu2.jpg)","backgroundRepeat":"repeat-x","backgroundPosition":"top","backgroundColor":"#e8e7e7"}} className="modal-body text-left">
                                {
                                this.state.categorias.map((item, key) =>
                                <a key={key} className="dropdown-item linkMenu menu-item" href={`/${item.url_categoria}`}>
                                    <i className="fas fa-chevron-right"></i> {item.name}<br/>
                                </a>
                                )
                                }

                            </div>
                            <div style={{"backgroundColor":"#e8e7e7"}} className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fechar</button>

                            </div>
                        </div>
                    </div>
                </div>
    </div> 



            )
    }
}

export default BotaoTodosProdutos;
