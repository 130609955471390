import Head from 'next/head'

const HeadIndex = () => {
    return(
        <Head>
            <title>Bem Vindo a LojaMundi os Melhores Produtos!</title>
            <meta name="description" content="LojaMundi Tecnologia sem Limites! Tudo que você precisa para Telefonia IP, Informática, Redes e Embarcados em Geral com suporte e garantia no Brasil."/>
            <meta name="keywords" content=""/>

        </Head>
        )
}

export default HeadIndex
