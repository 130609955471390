import React, {Component} from 'react';
import CardProduto from './CardProduto'

class AdicionadoRecente extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <div className="pb-5">
                <div className="text-center pt-5 pb-5">
                    <h2>
                        <strong>Produtos Adicionados Recentemente</strong>
                    </h2>
                </div>
                <div className="container">
                    <div className="card-deck pt-5 pb-5">
                        <div className="container">
                            <div className="row mb-3">
                                <CardProduto productCards={this.props.addRecent} cards={3}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default AdicionadoRecente;
