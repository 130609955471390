import React, { Component } from 'react';
import Head from '../components/Head';
import JsBody from '../components/JsBody';
import BodyIndex from '../components/home/BodyIndex';
import HeadIndex from '../components/home/HeadIndex'
import ProviderCarrinho from '../components/ContextLojamundi.js'
import {getIsMobile} from '../funcoes/index.js'

class Index extends Component {
    constructor(props){
        super(props)
        this.state = {
        }
    }   
    static async getInitialProps({req}){
        return {
            destaquesHome:req.destaquesHome,
            addRecent:req.addRecent,
            filtroCategoria:req.filtroCategoria,
            isMobile:getIsMobile(req)
        }
    }

    render(){
        return(
            <ProviderCarrinho>
            <Head />
            <HeadIndex/>
            <BodyIndex destaquesHome={this.props.destaquesHome} filtroCategoria={this.props.filtroCategoria} addRecent={this.props.addRecent} isMobile={this.props.isMobile}/>
            <JsBody />
    </ProviderCarrinho>
        )
    }
}
export default Index
