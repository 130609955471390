import React, { Component } from 'react';
import Busca from '../comuns/Busca.js'
import Box0800PC from '../comuns/Box0800PC.js'
import {ContCarrinho} from '../ContextLojamundi'


class Slider extends Component {
   constructor(props){
      super(props)
      this.state = {
      }
   }
   static contextType = ContCarrinho
   render(){
      return (
         <div id="carouselSite" className="carousel slide col-md" data-ride="carousel" data-interval="12000">
            <ol className="carousel-indicators p-2">
               <li data-target="#carouselSite" data-slide-to="0" className="active"></li>
               <li data-target="#carouselSite" data-slide-to="1" ></li>
               <li data-target="#carouselSite" data-slide-to="2" ></li>
               <li data-target="#carouselSite" data-slide-to="3" ></li>
               <li data-target="#carouselSite" data-slide-to="4" ></li>
               <li data-target="#carouselSite" data-slide-to="5" ></li>
               <li data-target="#carouselSite" data-slide-to="6" ></li>
            </ol>
            <div className="carousel-inner">
               <div className="carousel-item active">
                  <img src="/imagens/bg-caption1-transparente.png" className="img-fluid d-block" loading="lazy"/>
                  <div className="carousel-caption d-none d-lg-block sombra-texto-caption">
                     <a href="https://teste.directcallsoft.com/lojamundi" target="_blank" ><img className="pb-3 " src="/imagens/linha-voip-directcall.png"/></a>
                  </div>
               </div>
               <div className="carousel-item">
                  <img src="/imagens/bg-caption1-transparente.png" className="img-fluid d-block" loading="lazy"/>
                  <div className="carousel-caption d-none d-lg-block sombra-texto-caption">
                     <a href="https://www.lojamundi.com.br/w610w-telefone-wifi-fanvil">
                        <img  className="pb-4 img-fluid" src="/imagens/w611w-fanvil-telefone-ip.png" loading="lazy"/></a>
                  </div>
               </div>
               <div className="carousel-item">
                  <img src="/imagens/bg-caption1-transparente.png" className="img-fluid d-block" loading="lazy"/>
                  <div className="carousel-caption d-none d-lg-block sombra-texto-caption">
                     <a href="https://www.lojamundi.com.br/telefone-ip-wifi-wp810-grandstream">
                        <img  className="pb-4 img-fluid" src="/imagens/telefone-ip-wifi-wp810-grandstream.png" loading="lazy"/></a>
                  </div>
               </div>


               <div className="carousel-item">
                  <img src="/imagens/bg-caption1-transparente.png" className="img-fluid d-block" loading="lazy"/>
                  <div className="carousel-caption d-none d-lg-block sombra-texto-caption">
                     <a href="https://www.lojamundi.com.br/sala-comercial" ><img className="pb-4 " src="/imagens/fale-ao-vivo-banner-principal.png"/></a>
                  </div>
               </div>

               <div className="carousel-item">
                  <img src="/imagens/bg-caption1-transparente.png" className="img-fluid d-block" loading="lazy"/>
                  <div className="carousel-caption d-none d-lg-block sombra-texto-caption">
                     <a href="https://www.lojamundi.com.br/aluguel-de-tecnologia">
                        <img className="pb-5" src="/imagens/aluguel-de-tecnologia-telefone-ip.png" loading="lazy"/>
                     </a>
                  </div>
               </div>
               <div className="carousel-item">

                  <img src="/imagens/bg-caption1-transparente.png" className="img-fluid d-block" loading="lazy"/>
                  <div className="carousel-caption d-none d-lg-block sombra-texto-caption">
                     <a href="/gateway-gsm-asterisk"><img className="pb-5" src="/imagens/gatewayHomologado.png" loading="lazy"/></a>
                  </div>
               </div>
            </div>
            <a className="carousel-control-prev" href="#carouselSite" role="button"  data-slide="prev">
               <span className="carousel-control-prev-icon"></span>
               <span className="sr-only">Anterior</span>
            </a>
         </div>
      )
   }
}

export default Slider;
